import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { getDateSlot, creatNowDate, getDateForamte, getDateForamteSlot } from '../../../../hooks/date-time'
import createDatePicker from '../../../../hooks/date-picker/datapicker.general.conf'
import { createDatepickerButton } from './button'
import useLoad from '../../../../hooks/useLoad'

export default function GetDatePicker({ updateSchedule, date: { START }, showBlockOption }) {
    // console.log('Render picker')
    const loading = useLoad()

    const fetch = async () => {
        loading.loadin()
        await updateSchedule()
        const frontStart = getDateForamte(START.current)
        START_DATE.current = frontStart
        loading.setDate(START.current)
        // setStart(frontStart)
        // loading.setDate(START)
        loading.loadout()
    }

    useEffect(() => {
        fetch()
    }, [])

    // const [START_DATE, setStart] = useState([]);
    const START_DATE = useRef([]);

    useMemo(() => {
        // console.log('useEffect picker')
        const frontStart = getDateForamte(START.current)
        START_DATE.current = frontStart
        // setStart(frontStart)
    }, [])

    useEffect(() => {
        const elementList = [
            {
                element: 'datepStartUser',
                isRTLboolean: false,
                show: 'both'
            }
        ]
        const cb = (input, inst) => {
            const coordinate = input.getBoundingClientRect();
            inst.dpDiv.css({
                'top': input.offsetHeight + coordinate.top + 'px',
            });
        }

        const cb2 = (date) => {
            START_DATE.current = date
            START.current = getDateForamteSlot(date)
            fetch()
        }
        createDatePicker({ START_DATE, elementList, cb, cb2 })
    }, [])


    function handleChange(event) {
        console.log(event.target.value);
    }
    const BUTTON = createDatepickerButton(fetch, showBlockOption)
    return (
        <div className="container mx-auto position-fixed start-50 translate-middle-x block-date-pocker"
            style={{
                "width": '100%',
                "zIndex": "30"
            }}>
            <div className="row  sticky-top border bg-secondary  border-4 rounded-pill"

                style={{
                    "border": '6px solid #c0c0c0',
                    "zIndex": "30"
                }}
            >
                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                    <div className="container px-1">
                        <div className="row gx-2">
                            <div className="col-2"
                                style={{
                                    "width": '18%',
                                }}>
                                <div className="position-relative top-50 start-50 translate-middle btn-group" role="group" aria-label="Basic mixed styles example">
                                    <input className="position-relative bg-secondary text-light form-control h-40px"
                                        style={{
                                            "borderColor": 'transparent',
                                        }}
                                        onChange={handleChange}
                                        name="date" type="text" autoComplete="off" id="datepStartUser"
                                        value={START_DATE.current}

                                    ></input>
                                </div>
                            </div>

                            <div className="col-4">
                                <button className="btn btn-success text-light me-1"
                                    onClick={() => {
                                        console.log(START_DATE)
                                        console.log(START)
                                        BUTTON.goOverDate({ START })
                                    }}
                                >Перейти</button>
                                <button className=" btn btn-success me-1" defaultValue="true" name="oneless"
                                    onClick={() => {
                                        BUTTON.previousDate({ START })
                                    }}
                                ><i className="fa fa-arrow-left"></i></button>
                                <button className=" btn btn-success text-light me-1" defaultValue="true" name="today"
                                    onClick={() => {
                                        BUTTON.todayDate({ START })
                                    }}
                                >Сегодня</button>
                                <button className=" btn btn-success me-1" defaultValue="true" name="onemore"
                                    onClick={() => {
                                        BUTTON.nextDate({ START })
                                    }}
                                ><i className="fa fa-arrow-right"></i></button>
                            </div>
                            <div className="col-2">
                            </div>

                            <div className="col-2">
                                <input className="position-relative top-50 start-50 translate-middle bg-secondary bg-gradient text-light  form-control timeslot-link"
                                    type="text"
                                    autoComplete="off" placeholder="Добавить" name="text" form="adder"></input>
                            </div>

                            <div className="col">
                                <button className="position-relative top-50 start-50 translate-middle w-100 p-2 btn btn-info "
                                    form="adder" type="button submit" name="adder" defaultValue="true"><i className="fa fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}