import { useState, useEffect, useRef, useMemo } from 'react';
import { getSchedule, getReserv } from './functions/get-schedule'
import { getDateForamteSlot, getDateSlot, getDateForamte, setDateSlot, setDateForamteSlot } from '../../hooks/date-time'

import { createDatepickerButton } from './functions/button2'
import createDatePicker from '../../hooks/date-picker/datapicker.general.conf'
import { getDateMap } from './functions/create-date-map'
import { CreateWeek } from './element/week-block'

import { CreateAllSlot } from './element/all-slot';
import { CreateReservation } from './element/reservation-block'
import { CreateSchedule } from './element/schedule-block'

import { CreateButtonSetter } from './element/button-delay';

import { CreateRadioStatus } from './element/radio-status-block'
import { CreateTime } from './element/time-block'

export default function App() {
  console.log('CreateApp')
  const [SCHEDULE, setSchedule] = useState([])
  const [RESERVED, setReserved] = useState([])
  const DATE_PICK = useRef()

  const START = useRef()
  const END = useRef()

  const dateMapRef = useRef()

  const updateSchedule = async () => {
    dateMapRef.current = getDateMap({ START, END, DATE_PICK })
    const resultSh = await getSchedule(DATE_PICK.current)
    const resultRes = await getReserv(DATE_PICK.current)

    // scheduleRef.current = resultSh
    setSchedule(resultSh)
    setReserved(resultRes)
  }
  useMemo(() => {
    console.log('useMemo')
    const nowDate = getDateForamteSlot()
    DATE_PICK.current = nowDate
  }, [])

  useEffect(() => {
    console.log('updateSchedule')
    createDatePicker({ DATE_PICK })
    updateSchedule()
    return () => {
      console.log('setDateSlot')
      setDateSlot('')
      setDateForamteSlot('')
    }
  }, [])

  const BUTTON = createDatepickerButton(updateSchedule)
  const All_Slot_Block = RESERVED.length != 0
    ? <CreateAllSlot propSchedule={SCHEDULE} propReserved={RESERVED} />
    : null
  const RESERVATION_BLOCK = <CreateReservation propSchedule={RESERVED} propCallback={setReserved} />
  // const dateNow = getDateForamteSlot()
  console.log(DATE_PICK.current)
  return (

    <div>

      <div className="d-flex flex-row justify-content-between">
        <div className="col-xs-4">
          <div className="input-group">
            <input
              type="text" form="logs" name="begin" className="form-control" id="datep"
              // value={dateNow === '' ? creatNowDateFormate() : dateNow.split('-').reverse().join('-')}
              value={getDateForamte(DATE_PICK.current)}
              onChange={() => { }}
            />
            <button id="btnDate" className="btn btn-success ms-2"
              onClick={() => {
                BUTTON.goOverDate()
              }}
            >Посмотреть</button>
          </div>
        </div>
        <div className="container-fluid">
          <button type="button" className="ms-2 btn btn-success text-light" id="regular_save"
            onClick={() => {
              BUTTON.previousWeek({ START, END, DATE_PICK })
            }}
          >
            <i className="fa fa-arrow-left"></i>
          </button>
          <button type="button" className="ms-2 btn btn-success" id="regular_cleare"
            onClick={() => {
              BUTTON.todayWeek({ START, END, DATE_PICK })
            }}
          >Эта неделя </button>
          <button type="button" className="ms-2 btn btn-success" id="regular_cleare"
            onClick={() => {
              BUTTON.nextWeek({ START, END, DATE_PICK })
            }}
          >
            <i className="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>

      <CreateRadioStatus />

      {(SCHEDULE.length === 0) ? <div>Загрузка...</div> :
        (SCHEDULE.length === 0) ? <div>Загрузка...</div> :
          <>
            <CreateWeek propCallback={updateSchedule} weekNameMap={dateMapRef.current} datePick={{ DATE_PICK }} />
            <table className="container-fluid table-hover fs-table-10 min-vw-98 table-bordered text-checkbox text-center">
              <thead>
                <tr>
                  <td className="w-10"></td>
                  <CreateTime propTime={RESERVED.reservation} />
                </tr>
                <tr>
                  <td className="w-10" >Всего</td>
                  {All_Slot_Block}
                </tr>
                <tr>
                  <td className="w-10" >Бронь</td>
                  {RESERVATION_BLOCK}
                </tr>
              </thead>
              <tbody>
                <CreateSchedule propSchedule={SCHEDULE} propCallback={setSchedule} />
                {/* {scheduleRef.current} */}
                <tr>
                  <td className="w-10" >Бронь</td>
                  {RESERVATION_BLOCK}
                </tr>
                <tr>
                  <td className="w-10" >Всего</td>
                  {All_Slot_Block}
                </tr>
              </tbody>
            </table>
          </>
      }
      <CreateButtonSetter schedule={SCHEDULE} reservation={RESERVED} datePick={{ DATE_PICK }} />
    </div>
  );
}