import {useState, useCallback, useRef, useEffect} from 'react';

const useStateWithCallback = initialState => {
  // console.log('useStateWithCallback')
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null);

  const updateState = useCallback((newState, cb) => {
    //console.log(newState)
    //console.log(cb)
    cbRef.current = cb;

    setState(prev => typeof newState === 'function' ? newState(prev) : newState);
  }, []);

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [state]);
  //console.log(state)
  // console.log(updateState)
  return [state, updateState];
}

export default useStateWithCallback;