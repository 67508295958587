const CONFIG = {
    defaultClass: 'border-none form-check-input ',
    class: {
        'work': '',
        'lunch': 'bg-success',
        'quest': 'bg-warning',
        '0': ''
    },
    radioNameStatus: 'work', // По умолчанию высталвено на work, па смене радио кнопок сработает setGender который переназначит значение 
    radioClassColor: '',
    time_list: [
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30',
        '21:00',
        '21:30',
        '22:00',
        '22:30',
    ],
    radio: {
        'work': true,
        'lunch': false,
        'quest': false,
    },
    cheked: {
        'work': true,
        'lunch': true,
        'quest': true,
        '0': false
    },
    week: {
        1: {
            number: 1,
            description: 'Понедельник'
        },
        2: {
            number: 2,
            description: 'Вторник'
        },
        3: {
            number: 3,
            description: 'Среда'
        },
        4: {
            number: 4,
            description: 'Четверг'
        },
        5: {
            number: 5,
            description: 'Пятница'
        },
        6: {
            number: 6,
            description: 'Суббота'
        },
        0: {
            number: 7,
            description: 'Воскресенье'
        },
    }
}
export { CONFIG} ;