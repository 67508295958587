import { BrowserRouter, Routes, Route } from 'react-router-dom';


import Share from './pages/Share/Room';
import View from './pages/View/Room';
import ViewMobile from './pages/View/Room'
import Main from './pages/Main';
import NotFound404 from './pages/NotFound404';
import Mobile from './pages/Mobile';
import useMain, { } from './hooks/useMenu';
import Navbar from '../../components/Navbar';

const client = window.location.host === "datsy.ru" ? true : false;
export default function App() {
  return (
    
      <Routes >
        {/* '/share/room/:id' для клиентской части */}
        <Route path='/sharing/room/:id' element={<Share />} />
        <Route path='/share/room/:id' element={<Share />} />
        <Route path='/sharing/view/room/:id' element={<View />} />
        <Route path='/view/room/:id' element={<View />} />

        <Route path='/room/:id' element={<Share />} />

        <Route path='/sharing' element={<Main />} />
        <Route path='/sharing/mobile-share/' element={<Mobile />} />
        <Route path='/sharing/mobile-share/room/:id' element={<Mobile />} />
        <Route path="/" element={<Main />}></Route>
        <Route path='*' element={<NotFound404/>}/>
      </Routes >
    
  );
}

