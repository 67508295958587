import { useState, useEffect } from 'react';
import { radioStatus } from '../functions/get-radio-status'


function CreateRadioStatus() {
    // console.log('CreateRadioStatus')
    const [RADIO_VALUE, setRadioStatus] = useState([]);
    const RADIO_STATUS = radioStatus()
    useEffect(() => {
        RADIO_STATUS.setRadioStatus('work', setRadioStatus)
    }, [])
    return (
        <div className="col mt-4">
        <div className="container-fluid">
          <div className="form-check form-switch">
            <input className="form-check-input " type="radio" name="flexRadioDefault" id="work"
              checked={'work' === RADIO_VALUE ? true : false}
              onChange={() => {
                RADIO_STATUS.setRadioStatus('work', setRadioStatus)
              }}
            /><label
              className="btn padding-btn-0 text-light form-check-label bg-primary rounded-pill w-75px text-center"
              htmlFor="work">Смена
            </label>
          </div>
          <div className="form-check form-switch">
            <input className="form-check-input " type="radio" name="flexRadioDefault" id="lunch"
              checked={'lunch' === RADIO_VALUE ? true : false}
              onChange={() => {
                RADIO_STATUS.setRadioStatus('lunch', setRadioStatus)
              }}
            /><label
              className="btn padding-btn-0 text-light form-check-label bg-success rounded-pill w-75px text-center"
              htmlFor="lunch">Обед
            </label>
          </div>
          <div className="form-check form-switch">
            <input className="form-check-input " type="radio" name="flexRadioDefault" id="quest"
              checked={'quest' === RADIO_VALUE ? true : false}
              onChange={() => {
                RADIO_STATUS.setRadioStatus('quest', setRadioStatus)
              }}
            /><label
              className="btn padding-btn-0 text-light form-check-label bg-warning rounded-pill w-75px text-center"
              htmlFor="quest">Квест
            </label>
          </div>
        </div>
      </div>
    )
}

export { CreateRadioStatus }