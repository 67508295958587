import { getRole } from '../function/group-and-role'
import { deleteUser } from '../function/delete-user'
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';

function AddUserForTable({operator, name, callback}) {
    const ROLE = getRole()
    const KILL_ID = useRef()

    console.log(operator)
    return (
        operator.map((data, key) => {
            console.log(data.name)
            return (
                <tr key={data.id}>
                    <td>{data.id}</td>
                    <td>{data.name}</td>
                    <td>{data.login}</td>
                    <td>{ROLE[data.role]['name']}</td>
                    <td>{name}</td>
                    <td>{
                        (data.can_assign_events === "true") ?
                            <span className="badge rounded-pill bg-success">ДА</span> :
                            <span className="badge rounded-pill bg-danger">НЕТ</span>
                    }</td>
                    <td>{
                        (data.alter_any_event === "true") ?
                            <span className="badge rounded-pill bg-success">ДА</span> :
                            <span className="badge rounded-pill bg-danger">НЕТ</span>
                    }</td>
                    <td>{
                        (data.can_add_events === "true") ?
                            <span className="badge rounded-pill bg-success">ДА</span> :
                            <span className="badge rounded-pill bg-danger">НЕТ</span>
                    }</td>
                    <td>{data.last_seen}</td>
                    <td>
                        <button type="button" tooltip="Редактировать" value={data.id} className="btn btn-info"
                            onClick={() => {
                                window.open(`${window.location.origin}/users/edit/user=${data.id}`)
                            }}
                        >
                            <i className="fa fa-pencil-alt"></i>
                        </button>
                        <button type="button" tooltip="Убить несчастного" value={data.id} className="btn btn-danger" flow="left"
                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                            onClick={() => {
                                KILL_ID.current = data.id
                                console.log(KILL_ID.current)
                                // deleteUser(data.id)
                            }}
                        >
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </button>

                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered ">
                                <div className="modal-content bg-dark">

                                    <div className="modal-body">
                                        Пользователь будет удален, подтвердите действие
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                            onClick={async () => {
                                                console.log(KILL_ID.current)
                                                await deleteUser(KILL_ID.current)
                                                await callback()
                                            }}
                                        > Удалить</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr >
            )
        })
    )
}

export { AddUserForTable }