import { useState, useEffect, useRef } from 'react';
import socket from '../../socket';
import ACTIONS from '../../socket/actions';
import { useParams, useNavigate } from 'react-router';
import { v4 } from 'uuid';

export default function Main() {
  const history = useNavigate();
  const [rooms, updateRooms] = useState([]);
  const [roomsMobile, updateRoomsMobile] = useState([]);
  const rootNode = useRef();
  let test = []


  useEffect(() => {
    socket.on(ACTIONS.SHARE_ROOMS, ({ rooms = [] } = {}) => {
      if (rootNode.current) {
        updateRooms(rooms);
      }
    });
  }, []);

  useEffect(() => {
    socket.on(ACTIONS.SHARE_ROOMS_MOBILE, ({ room_mobile = [] } = {}) => {
      if (rootNode.current) {
        updateRoomsMobile(room_mobile);
      }
    });
  }, []);

  useEffect(() => {
    socket.emit('auth', { value: document.cookie.split('session_id=')[1] })
    socket.emit('get-room')
  }, []);

  window.addEventListener('click', function (e) {
    console.log('screen: ' + e.screenX + 'x' + e.screenY + '\npage: ' + e.pageX + 'x' + e.pageY + '\nclient: ' + e.clientX + 'x' + e.clientY);
  }, false);

  return (
    <div className="container  mw-100">

      <div className="col-2 float-end " >
        <div>
          <button id="new_person" type="button submit" className="btn btn-success float-end">Скачать приложение</button>
        </div>


        <div className="mt-5">
          <input className='form-control w-300px float-end' defaultValue='https://app.datsy-test.ru/' style={{
            width: '',
          }}>
          </input>
        </div>
      </div>
      <div ref={rootNode}>
        <h2 className="ms-2">PC</h2>
        <button className="ms-2 btn btn-success" value="true" name="oneless" onClick={() => {
          history(`/sharing/view/room/${v4()}`);
        }}>Create New Room</button>

        {/* <h2 className="ms-2">PC</h2>
        <button className="ms-2 btn btn-success" value="true" name="oneless" onClick={() => {
          history(`/sharing/share/room/${v4()}`);
        }}>Start Share Room</button> */}

        <ul>
          {rooms.map(roomID => (
            <li key={roomID}>
              {roomID}
              <button className="ms-2 btn btn-success" value="true" name="oneless" onClick={() => {
                history(`/sharing/view/room/${roomID}`);
              }}>JOIN ROOM</button>
            </li>
          ))}
        </ul>

        {/* <h2 className="ms-2">TEST</h2>

        {
          roomsMobile.map(roomID => (
            <li key={roomID.id}>
              {roomID.id}
              <button className="ms-2 btn btn-success" onClick={() => {
                history(`/sharing/view/room/${roomID.id}`);
              }}>JOIN ROOM</button>
            </li>
          ))
        } */}

      </div>

      <div ref={rootNode}>
        <div className="remoteStreams">
          <h2 className="ms-2 ">Mobile</h2>

          <ul>
            {
              roomsMobile.map(roomID => (
                <li key={roomID.id}>
                  {roomID.id}
                  <button className="ms-2 btn btn-success" onClick={() => {
                    history(`/sharing/mobile-share/room/${roomID.id}`);
                  }}>JOIN ROOM</button>
                </li>
              ))
            }
          </ul>
        </div>
      </div>


    </div>
  );
}