import { getDateSlot } from '../../../hooks/date-time'
import { setUserPermission } from '../../../hooks/permission'
import { api } from '../../../service/api-url';

const getSchedule = async (date, cb) => {
    try {
        // console.log('getSchedule')
        const arrayResult = {
            schedule: {},
            user: {},
            nowDateTime: '',
            blockCount: 0,
        }

        // let date = getDateSlot()
        const url = `${api()}/api/main-events/?date=${date}`;

        let getResult = await fetch(url, {
            "body": null,
            "method": "GET",
            "credentials": "include"
        })
        const result = await getResult.json();
        console.log(result)
        arrayResult.schedule = Object.entries(result.DataTimeSlot)
        arrayResult.schedule = arrayResult.schedule.filter(([time, valu]) => {
            return (time >= '08:00' && time < '23:00')
        })
        const count = arrayResult.schedule.length
        arrayResult.blockCount = Math.ceil(count / 2) - 1

        arrayResult.nowDateTime = result.nowDateTime
        setUserPermission(result.User)
        arrayResult.user = result.User
        
        return arrayResult
    }
    catch (e) {
        console.error(e.message)
    }
}

export { getSchedule }