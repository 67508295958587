function CreateSelect(selectArray, ref) {
    // console.log('CreateSelect')
    // console.log(current)
    // console.log(selectArray[1].id)
    // ref.value = selectArray[1].id
    let valueSelect = ref.value !== '' ? selectArray[ref.value].id : selectArray[0].id
    return (
        <select defaultValue={valueSelect} className="form-control" name="new_role"
            onChange={(e) => {
                console.log('onChange')
                ref.value = e.target.value

                console.log(e.target.value)

            }}
        >
            {Object.entries(selectArray).map(body => {
                const name = body[1].name;
                const key = body[1].id;
                return (
                    <option
                        key={key}
                        value={key}
                    >{name}</option>
                )
            })}
        </select>
    )
}

export { CreateSelect }