import { useParams } from 'react-router';
import { useLocation, Navigate, Outlet } from "react-router-dom";

import { useState, useEffect, useRef, createContext, useContext } from 'react';
// import { getUserInfo } from './functions/get-user-info'
import { setAuth } from './functions/set-auth'
import { useNavigate } from 'react-router';
import { CheckAuth } from '../../service/auth-check'

export default function App({ authContext }) {
    const [LOGIN, setLogin] = useState([])
    const [PASSWORD, setPasswod] = useState([])
    const inputRef = useRef()
    const history = useNavigate();
    const auth = useContext(authContext)
    const effectStatus = useRef()
    const test = ()=>{
        <Navigate to="/" replace />
    }
    test()
    useEffect(() => {
        inputRef.current = {
            'login': { 'value': '' },
            'password': { 'value': '' },
        }
        return () => {
            effectStatus.current = false;
        }
    }, [])

    return (
        auth.user === true ? <Navigate to="/" replace /> :
        <div>
            <div className="container  mw-100">
                <div className="d-flex flex-row justify-content-center ">
                    <div className="alert w-50">
                        <form action="" method="post">
                            <fieldset>
                                <legend>Представьтесь, пожалуйста</legend>
                                <div className="form-group">
                                    <label>Логин</label>
                                    <input type="text" className="form-control" name="login" placeholder="Логин" required
                                        defaultValue=""
                                        onChange={(e) => {
                                            inputRef.current.login.value = e.target.value
                                            console.log(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Пароль</label>
                                    <input type="password" className="form-control" name="password" placeholder="Пароль" required
                                        defaultValue=""
                                        onChange={(e) => {
                                            inputRef.current.password.value = e.target.value
                                            console.log(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className="d-grid gap-2 pt-1">
                                    <button type="button" className="btn btn-success"
                                        onClick={async () => {
                                            const result = await setAuth(inputRef.current)
                                            if (result["key-status"] === 200) {
                                                // setTimeout(() => {
                                                    auth.signin(() => {

                                                        history(`/`);
                                                    });
                                                // }, 1000);
                                            }
                                            console.log(inputRef.current)
                                        }}
                                    >Поехали</button>
                                </div>
                                <div className="d-grid gap-2 pt-1">
                                    <button type="button" className="btn btn-success"
                                        onClick={() => {
                                            CheckAuth()
                                            console.log(inputRef.current)


                                        }}
                                    >ТЕСТ</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

// ReactDOM.render(<App />, document.getElementById('root'));