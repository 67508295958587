import { useParams } from 'react-router';
import { useState, useEffect, useRef } from 'react';
import { getUserInfo, userUserInfo } from './functions/user-request'
import { CreateSelect } from './element/select'
import { CreateRadioStatus } from './element/radio-status-block'
import { CreateSchedule } from './element/schedule-block'
import { CreateChangeTimeValue } from './element/radio-change-time-block'
import { Button, Form, FormCheck, Row, Col, InputGroup, Jumbotron, FormGroup, FormControl } from 'react-bootstrap';

import { getDateSlot, getDateForamteSlot, getBacktDate, setDateSlot, creatNowDateFormate, setDateForamteSlot } from '../../hooks/date-time'
import createDatePicker from '../../hooks/date-picker/datapicker.general.conf'


export default function App() {
    const [USER_INFO, setUserInfo] = useState([])
    const { id: userId } = useParams();
    const START = useRef()
    const inputRef = useRef()
    // console.log(USER_INFO)

    const effectStatus = useRef()

    const updateSchedule = async () => {
        effectStatus.current = true
        const resultUserInfo = await getUserInfo(userId)
        inputRef.current = {
            'name': { 'value': resultUserInfo.user.name },
            'login': { 'value': resultUserInfo.user.login },
            'password': { 'value': '' },
            'slack_id': { 'value': resultUserInfo.user.slack_id },
            'role': { 'value': resultUserInfo.user.role },
            'user_group': { 'value': resultUserInfo.user.user_group },
            'schedule': { 'value': resultUserInfo.user.schedule },
            'alter_any_event': { 'value': resultUserInfo.user.alter_any_event },
            'can_assign_events': { 'value': resultUserInfo.user.can_assign_events },
            'can_add_events': { 'value': resultUserInfo.user.can_add_events },
            'start_work': { 'value': resultUserInfo.user.start_work },
        }
        createDatePicker({ START })
        if (effectStatus.current) {
            setUserInfo(resultUserInfo)
        }
    }

    useEffect(() => {
        START.current = getDateSlot()
        updateSchedule()
        return () => {
            effectStatus.current = false;
        }
    }, [])

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        console.log(form)
        if (form.checkValidity() === true) {
            userUserInfo(userId, inputRef.current)
            updateSchedule()
        }
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);
    };

    return (
        (USER_INFO.length === 0) ? <div>Загрузка...</div> :
            <div>
                <div className="container-fluid">
                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="row g-3 needs-validation">
                        <div className="col-md-4">
                            <Form.Group controlId="validationCustom01">
                                <Form.Label>Имя</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Имя"
                                    defaultValue={inputRef.current.name.value}
                                    onChange={(e) => {
                                        inputRef.current.name.value = e.target.value
                                        console.log(e.target.value)
                                    }}
                                />
                                <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Пожалуйста, заполните окно.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="validationCustom02">
                                <Form.Label>Логин</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Логин"
                                    defaultValue={inputRef.current.login.value}
                                    onChange={(e) => {
                                        inputRef.current.login.value = e.target.value
                                        console.log(e.target.value)
                                    }}
                                />
                                <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Пожалуйста, заполните окно.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="validationCustom03">
                                <Form.Label>Пароль? (генератор есть
                                    <a href="https://www.lastpass.com/ru/password-generator" target="_blank" rel="noreferrer">тут</a> , например)</Form.Label>
                                <Form.Control

                                    type="text"
                                    placeholder="*********"
                                    defaultValue={inputRef.current.password.value}
                                    onChange={(e) => {
                                        inputRef.current.password.value = e.target.value
                                        console.log(e.target.value)
                                    }}
                                />
                                <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Пожалуйста, заполните окно.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="validationCustom04">
                                <Form.Label>Slack ID</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="slack id"
                                    defaultValue={inputRef.current.slack_id.value}
                                    onChange={(e) => {
                                        inputRef.current.slack_id.value = e.target.value
                                        console.log(e.target.value)
                                    }}
                                />
                                <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Пожалуйста, заполните окно.
                                </Form.Control.Feedback>

                            </Form.Group>
                        </div>

                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Label>Роль</Form.Label>
                            {CreateSelect(USER_INFO.roles, inputRef.current.role)}

                            <Form.Label>Группа</Form.Label>
                            {CreateSelect(USER_INFO.groups, inputRef.current.user_group)}

                            <Form.Label>Тип Календаря</Form.Label>
                            {CreateSelect(USER_INFO["scheduele-type"], inputRef.current.schedule)}

                            <Form.Check>
                                <FormCheck.Input
                                    defaultChecked={inputRef.current.alter_any_event.value === 'true' ? 'checked' : null}
                                    bsPrefix="form-check-input"
                                    onChange={(e) => {
                                        const { alter_any_event } = inputRef.current
                                        alter_any_event.value === "true" ? alter_any_event.value = 'false' : alter_any_event.value = "true"
                                        console.log(e)
                                    }}
                                ></FormCheck.Input>
                                <FormCheck.Label
                                    bsPrefix="custom-control-label-primary text-light"
                                >Разрешить изменять чужие ивенты?</FormCheck.Label>
                            </Form.Check>
                            <Form.Check>
                                <FormCheck.Input
                                    defaultChecked={inputRef.current.can_assign_events.value === 'true' ? 'checked' : null}
                                    bsPrefix="form-check-input"
                                    onChange={(e) => {
                                        const { can_assign_events } = inputRef.current
                                        can_assign_events.value === "true" ? can_assign_events.value = "false" : can_assign_events.value = "true"
                                        console.log(e.target.value)
                                    }}
                                ></FormCheck.Input>
                                <FormCheck.Label
                                    bsPrefix="custom-control-label text-light"
                                >Может асайнить ивенты?</FormCheck.Label>
                            </Form.Check>
                            <Form.Check>
                                <FormCheck.Input
                                    defaultChecked={inputRef.current.can_add_events.value === 'true' ? 'checked' : null}
                                    bsPrefix="form-check-input"
                                    onChange={(e) => {
                                        const { can_add_events } = inputRef.current
                                        can_add_events.value === "true" ? can_add_events.value = "false" : can_add_events.value = "true"
                                        console.log(e.target.value)
                                    }}
                                ></FormCheck.Input>
                                <FormCheck.Label
                                    bsPrefix="custom-control-label text-light"
                                >Может добавлять ивенты?</FormCheck.Label>
                            </Form.Check>
                        </Form.Group>



                        <Form.Group as={Col} md="4" controlId="datep" >
                            <Form.Label>Первая дата</Form.Label>
                            <div className="input-group mb-3 w-200px">
                                <Form.Control
                                    type="text"
                                    // id='datep'
                                    // controlid='datep'
                                    aria-describedby="inputGroupPrepend"

                                    defaultValue={inputRef.current.start_work.value}
                                    className="input-group w-50"
                                />
                            </div>

                            <Form.Control.Feedback type="invalid">
                                Please choose a username.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className="container-fluid mt-5">
                            <div className="row">
                                <div className="col-4">
                                    <Button type="submit" variant="success"

                                    >Сохранить</Button>
                                    <Button type="button" variant="success"
                                        onClick={() => {
                                            console.log(inputRef.current)
                                        }
                                        }
                                    >Тест</Button>
                                    <Button className="ms-2" type="submit" href="https://app.datsy-test.ru/users" variant="danger"
                                    >Отмена</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>

                <div className="progress mt-5">
                    <div className="progress-bar" role="progressbar" style={{
                        "width": "100%"
                    }}
                        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div className="row w-62vw mt-4">

                    {/* <div className="input-group mb-3 w-620px "> */}

                        <CreateRadioStatus />
                        <CreateChangeTimeValue />
                    {/* </div> */}
                </div>


                <CreateSchedule schedule={USER_INFO.user.schedule} />

            </div>
    );
};

// ReactDOM.render(<App />, document.getElementById('root'));