// import { getDateSlot, getDateForamteSlot } from '../../../hooks/date-time'
import { CONFIG } from '../config';
import { api } from '../../../service/api-url';

const setAuth = async (ref) => {
    // console.log('getScheduleReg')

    try {
        const url = `${api()}/auth/login-submit.php`;
        let setResult = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(ref),
            credentials: "include"
        })
        const result = await setResult.json();
        return result
    }
    catch (e) {
        console.error(e.message)
    }
}


export { setAuth }