// Ноут Вин Едже

// с П уже с ноута пробовал но прерывалась связь 
// ПК http://joxi.ru/5mdRv51tJqlx5A
// 1,414 попугаев 
// скр http://joxi.ru/MAjkbZdFYdyRzr

// по процу сказал что слабоват но можно заниматься т.к. не сильно загружен
// по инету отправил к провайдеру т.ю. скорость очень низкая 

// еще телефон хотели проверить
// попросил с сафари заходить т.к. в приложении баг со связью https://jira.skyeng.tech/browse/MV-2135




import { setUserPermission, getUserPermission } from '../../../hooks/permission'
function showEventButton(time) {
    return {
        asign: (backTimeSlot, timeSlot, nowTimeSlot, checkAssig) => {
            const PERMISSION_ASIGN = getUserPermission().can_assign_events
            const resultPermission = PERMISSION_ASIGN === 'true' ? true : false
            const resultTime = backTimeSlot < nowTimeSlot
            const resultAsign = checkAssig === '0'
            return true === (resultTime && resultAsign && resultPermission) ? true : false
        },
        unAsign: (backTimeSlot, timeSlot, nowTimeSlot, checkAssig) => {
            const UserName = getUserPermission().name
            const PERMISSION_ASIGN = getUserPermission().can_assign_events
            const resultPermission = PERMISSION_ASIGN === 'true' ? true : false
            const resultName = UserName === checkAssig
            const resultTime = (backTimeSlot < nowTimeSlot) && (timeSlot > nowTimeSlot)
            return true === (resultTime && resultName && resultPermission) ? true : false
        },
        save: (backTimeSlot, nowTimeSlot, autorSlot) => {
            const USER_PERMISSION = getUserPermission()

            const PERMISSION_ALTER = USER_PERMISSION.alter_any_event === 'true' ? true : false
            const USER_ROLE = USER_PERMISSION.role < 3 ? true : false
            const USER_NAME = USER_PERMISSION.name === autorSlot ? true : false
            const resultTime = backTimeSlot > nowTimeSlot
            if (PERMISSION_ALTER && resultTime && autorSlot) {
                return true
            }else if(resultTime && USER_NAME){
                return true
            } else if (USER_ROLE) {
                return true
            } else {
                return false
            }
        },
        deleteSlot: (backTimeSlot, nowTimeSlot, autorSlot) => {
            const USER_PERMISSION = getUserPermission()

            const PERMISSION_ALTER = USER_PERMISSION.alter_any_event === 'true' ? true : false
            const USER_ROLE = USER_PERMISSION.role < 3 ? true : false
            const USER_NAME = USER_PERMISSION.name === autorSlot ? true : false
            const resultTime = backTimeSlot > nowTimeSlot
            if (PERMISSION_ALTER && resultTime && autorSlot) {
                return true
            }else if(resultTime && USER_NAME){
                return true
            } else if (USER_ROLE) {
                return true
            } else {
                return false
            }
        }
    }
}
export { showEventButton }