// // import React from "react";
// import { useState } from 'react';

// import Nouislider from "nouislider-react";
// // import "nouislider/distribute/nouislider.css";

// export default function App() {
//     const [start, setStart] = useState([10, 20]);
//     // state = { value: 0 };

//     const handleClick = () => {
//         console.log("handleClick")
//         // this.setState(prevState => ({ value: prevState + 10 }));
//         setStart([10, 20])
//     };


//     return (
//         <div>
//             <button onClick={handleClick}>Change state</button>
//             <Nouislider
//                 start={start}
//                 range={{
//                     min: 0,
//                     max: 50
//                 }}
//             />
//         </div>
//     );

// }


import React from "react";
import Nouislider from "nouislider-react";
// import "nouislider/dist/nouislider.css";

export default class KeyboardSlider extends React.Component {
  state = { value: 0 };

  handleClick = () => {
    this.setState(prevState => ({ value: prevState + 10 }));
  };

  render() {
    return (
      <div>
        <button onClick={this.handleClick}>Change state</button>
        <Nouislider
          start={this.state.value}
          range={{
            min: 0,
            max: 100
          }}
        />
      </div>
    );
  }
}