const GROUP_AND_ROLE = {
    GROUP: '',
    ROLE: '',
}



const setGroup= (body) => {
    // DATE_TIME_SLOT.dateTimeslot = '2022-02-05, 17:25:01'
    GROUP_AND_ROLE.GROUP = body
}

const getGroup = () => {
    return GROUP_AND_ROLE.GROUP
}

const setRole = (body) => {
    // DATE_TIME_SLOT.dateTimeslot = '2022-02-05, 17:25:01'
    GROUP_AND_ROLE.ROLE = body
}

const getRole = () => {
    return GROUP_AND_ROLE.ROLE
}

export {
    setGroup,
    setRole,
    getGroup,
    getRole
}