import { useEffect, useRef, useCallback } from 'react';
import freeice from 'freeice';
import useStateWithCallback from '../../hooks/useStateWithCallback';
import socket from '../../socket';
import ACTIONS from '../../socket/actions';
import STUN_TURN, { } from '../../hooks/STUN_TURN2';

export const LOCAL_VIDEO = 'LOCAL_VIDEO';


export default function useWebRTC(roomID) {
  function logConsole(text) {
    // console.log(text)
  }
  /* Логировать соккеты */
  useEffect(() => {
    socket.on("*", (event, data) => {
      logConsole(event)
      logConsole(data)
      if (data.iceCandidate !== undefined) {
        logConsole(data.iceCandidate.candidate)
      }
      if (data.sessionDescription !== undefined) {
        logConsole(data.sessionDescription.type)
      }
    });
  }, []);
  /* ------------------------------------- */

  /* Константы */
  const [clients, updateClients] = useStateWithCallback([]);
  const peerConnections = useRef({});
  const localMediaStream = useRef(null);
  const track = useRef(null);
  const peerMediaElements = useRef({
    [LOCAL_VIDEO]: null,
  });

  /* 
    Добавить новое видео-клиента в комнату
  */
  const addNewClient = useCallback((newClient, cb) => {
    updateClients(list => {
      if (!list.includes(newClient)) {
        return [...list, newClient]
      }

      return list;
    }, cb);
  }, [clients, updateClients]);

  useEffect(async () => {
    // console.log('adawd')
    // socket.on('message', handleMessage);
    const STUN = await STUN_TURN()
    // console.log(STUN)
    let count = 1000
    function addPeer(remoteId) {
      // console.log(STUN)
      peerConnections.current[remoteId] = new Peer(STUN.peerConnectionConfig, STUN.peerConnectionConstraints);
      peerConnections.current[remoteId].pc.onicecandidate = function (event) {
        // console.log(event)
        if (event.candidate) {
          // console.log("Отправлено")
          send('candidate', remoteId, {
            label: event.candidate.sdpMLineIndex,
            id: event.candidate.sdpMid,
            candidate: event.candidate.candidate
          });
        }
      };
      peerConnections.current[remoteId].pc.ontrack = function (event) {

        let element = document.querySelector('#remoteVideosContainer');
        element.appendChild(peerConnections.current[remoteId].remoteVideoEl);
        peerConnections.current[remoteId].remoteVideoEl.srcObject = event.streams[0]
      };


      // peerConnections.current[remoteId] = peer;

      return peerConnections.current[remoteId];
    }
    function answer(remoteId) {
      var pc = peerConnections.current[remoteId].pc;
      console.log(pc)
      const answer = (sessionDescription) => {
        console.log('answer callback')
        pc.setLocalDescription(sessionDescription);
        send('answer', remoteId, sessionDescription);
      }
      console.log('answer')

      pc.createAnswer().then(answer);
      console.log('answer')
    }
    function offer(remoteId) {
      var pc = peerConnections.current[remoteId].pc;
      pc.createOffer(
        function (sessionDescription) {
          pc.setLocalDescription(sessionDescription);
          send('offer', remoteId, sessionDescription);
        },
        error
      );
    }
    function handleMessage(message) {
      var type = message.type,
        createOffer = message.createOffer,
        from = message.from,
        pc = (peerConnections.current[from] || addPeer(from)).pc;
      // console.log(peerConnections.current)
      // console.log('received ' + type + ' from ' + from);

      // if (createOffer === false) {
      //   toggleLocalStream(pc);
      //   offer(from);
      //   return;
      // }
      // if (createOffer) {
      //   toggleLocalStream(pc);
      //   offer(from);
      //   return;
      // }
      switch (type) {
        case 'init':
          toggleLocalStream(pc);
          offer(from);
          break;
        case 'offer':
          toggleLocalStream(pc);
          pc.setRemoteDescription(new RTCSessionDescription(message.payload), function () { }, error);
          answer(from);
          break;
        case 'answer':

          pc.setRemoteDescription(new RTCSessionDescription(message.payload), function () { }, error);
          break;
        case 'candidate':
          if (pc.remoteDescription) {
            pc.addIceCandidate(new RTCIceCandidate({
              sdpMLineIndex: message.payload.label,
              sdpMid: message.payload.id,
              candidate: message.payload.candidate
            }), function () { }, error);
          }
          break;
      }
    }
    function toggleLocalStream(pc) {
      if (localMediaStream.current) {
        // console.log(localMediaStream);
        console.log(pc.getSenders());
        pc.addTrack(track.current, localMediaStream.current);
        // (!!pc.getSenders().length) ? pc.removeStream(localMediaStream.current) : pc.addTrack(track.current, localMediaStream.current);
      }
    }

    function offer(remoteId) {
      var pc = peerConnections.current[remoteId].pc;
      pc.createOffer(
        function (sessionDescription) {
          pc.setLocalDescription(sessionDescription);
          send('offer', remoteId, sessionDescription);
        },
        error
      );
    }

    function send(type, to, payload) {
      // console.log('sending ' + type + ' to ' + to);

      socket.emit(ACTIONS.SHARE_MESSAGE, {
        type: type,
        to: to,
        payload: payload
      });
    }

    function error(err) {
      console.log(err);
    }


    var Peer = function (pcConfig, pcConstraints) {
      this.pc = new RTCPeerConnection(pcConfig);
      this.remoteVideoEl = document.createElement('video');
      // this.remoteVideoEl.controls = true;
      this.remoteVideoEl.muted = true;
      this.remoteVideoEl.className = "border border-light";
      this.remoteVideoEl.width = "200";
      this.remoteVideoEl.autoplay = true;
    }

    socket.on(ACTIONS.ADD_PEER, handleMessage);
    socket.on(ACTIONS.SHARE_MESSAGE, handleMessage);

    return () => {
      socket.off(ACTIONS.ADD_PEER);
    }
  }, []);

  const startCapture = async function startCapture() {
    console.log('startCapwadture')
    localMediaStream.current = await navigator.mediaDevices.getDisplayMedia({ video: true });
    track.current = localMediaStream.current.getTracks()[0]
    socket.emit(ACTIONS.JOIN, { room: roomID })
    // handleNewPeer()
    addNewClient(LOCAL_VIDEO, () => {
      const localVideoElement = peerMediaElements.current[LOCAL_VIDEO];

      if (localVideoElement) {
        localVideoElement.volume = 0;
        localVideoElement.srcObject = localMediaStream.current;
      }
    });
  }

  useEffect(() => {
    const handleRemovePeer = ({ peerID }) => {
      console.log('handleRemovePeer')
      if (peerConnections.current[peerID]) {
        peerConnections.current[peerID].pc.close();
      }

      delete peerConnections.current[peerID];
      delete peerMediaElements.current[peerID];

      updateClients(list => list.filter(c => c !== peerID));
    };

    socket.on(ACTIONS.REMOVE_PEER, handleRemovePeer);

    return () => {
      socket.off(ACTIONS.REMOVE_PEER);
    }
  }, []);
  // useEffect(() => {

  //   socket.on(ACTIONS.ADD_PEER, handleMessage);

  //   return () => {
  //     socket.off(ACTIONS.ADD_PEER);
  //   }
  // }, []);

  // useEffect(() => {
  //   socket.emit('readyToStream', 'test')
  // }, [roomID]);

  // const provideMediaRef = useCallback((id, node) => {
  //   peerDatabase[id].remoteVideoEl = node;
  // }, []);

  const provideMediaRef = useCallback((id, node) => {
    peerMediaElements.current[id] = node;
  }, []);

  return {
    clients,
    startCapture,
    provideMediaRef
  };
}