import { getDateForamteSlot, getDateTimeSlot, getDateSlot, getTimeForButton } from '../../../hooks/date-time'
import { getSchedule } from '../function/get-schedule'
import { api } from '../../../service/api-url';
import useLoad from '../../../hooks/useLoad'

function useEventButton(callback, dateSlot) {
    const cookieClient = '';

    return {
        asign: async (eventID) => {
            let body = `&assign=${eventID}`;
            const url = `${api()}/api/slot-event/assign.php`;
            let getAsign = await fetch(url, {
                method: 'POST',
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    cookie: cookieClient
                },
                body: body,
                credentials: "include"
            })
            let result = await getAsign.json();
            callback()
        },
        unAsign: async (eventID) => {
            let body = '';
            body += `&unassign=${eventID}`;
            const url = `${api()}/api/slot-event/unassign.php`;
            let getAsign = await fetch(url, {
                method: 'POST',
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    cookie: cookieClient
                },
                body: body,
                credentials: "include"
            })
            let result = await getAsign.json();
            console.log(result)
            callback()
        },
        save: async (text, slotId) => {
            let body = `event-text=${text}`;
            body += `&save-slot=${slotId}`;
            const url = `${api()}/api/slot-event/save.php`;
            let getAsign = await fetch(url, {
                method: 'POST',
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    cookie: cookieClient
                },
                body: body,
                credentials: "include"
            })
            let result = await getAsign.json();
            console.log(result)

            callback()
        },
        create: async (text, time) => {
            // const date = getDateForamteSlot(getDateSlot())
            const date = dateSlot
            let body = `addinput=${text}`;
            body += `&slotname=${time}`;
            body += `&date=${date}`;
            const url = `${api()}/api/slot-event/add.php`;
            let getAsign = await fetch(url, {
                method: 'POST',
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    cookie: cookieClient
                },
                body: body,
                credentials: "include"
            })
            let result = await getAsign.json();
            console.log(result)
            callback()
            return result["key-status"]
        },
        deleteSlot: async (id) => {
            let body = '';
            body += `&deleteslot=${id}`;
            const url = `${api()}/api/slot-event/delete.php`;
            let getAsign = await fetch(url, {
                method: 'POST',
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    cookie: cookieClient
                },
                body: body,
                credentials: "include"
            })
            let result = await getAsign.json();

            callback()
        }
    }
}
export { useEventButton }