let radioStatusValue = '';

export const radioStatus = () => {
    return {
      setRadioStatus: (status, callback)=>{
        // console.log("setRadioStatus")
        radioStatusValue = status
        callback(status)
      },  
      getRadioStatus : ()=> {
        // console.log("getRadioStatus")
        
        return radioStatusValue;
      }
    }
}