import { getDateForamteSlot, getDateSlot, getBacktDate } from '../../../hooks/date-time'
import { api } from '../../../service/api-url';
// import { setUserPermission } from '../../../hooks/permission'

const getReports = async ({ ID, TEXT, AUTHOR, START, END }) => {
    try {
        console.log('getLogs')
        START = START.current.split('-').reverse().join('-')
        END = END.current.split('-').reverse().join('-')

        // whatSerach = whatSerach ? whatSerach : ''
        // let array = {}
        let body = {}
        body['start'] = START
        body['end'] = END

        // const url = window.location.origin + '/logs/include/getter.php' + whatSerach;
        const url = `${api()}/reports/api.php`;
        let getLogRes = await fetch(url, {
            method: 'POST',
            headers: {
                "content-type": "application/json; charset=UTF-8"
            },
            body: JSON.stringify(body)
        })
        let result = await getLogRes.json();

        return result
    }
    catch (e) {
        console.error(e.message)
    }
}

export { getReports }