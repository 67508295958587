import { useState, useEffect, useRef, useMemo, useCallback, createContext } from 'react';
import CreateBlock from './element/block'
import { getSchedule } from './function/get-schedule'
import { creatNowDate, setDateTimeSlot, getDateSlot, setDateSlot, setDateForamteSlot, getDateForamteSlot } from '../../hooks/date-time'
import GetDatePicker from './element/date-picker';
import { Loader } from '../../components/Loader'
import createDatePicker from '../../hooks/date-picker/datapicker.general.conf'
import eventUpdateInterval from './function/event-update'
import { LoadProvide } from '../../context/LoadProvider'
import useLoad from '../../hooks/useLoad'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';

export default function Room() {
  // console.log('Render Main')
  // const loading = useLoad()
  const [SCHEDULE, setSchedule] = useState([])
  // const SCHEDULE = useRef([])
  const START = useRef()
  const showBlockOption = useRef()
  const effectStatus = useRef()
  const documentRef = useRef(null)
  const updateSchedule = async () => {
    effectStatus.current = true
    const result = await getSchedule(START.current)
    setDateTimeSlot(result.nowDateTime)
    // setDateTimeSlot("2022-04-25, 20:00:00")
    if (effectStatus.current) {
      setSchedule(result)
      return result
    }
  }


  documentRef.current = document
  useEffect(() => {
    const timerId = eventUpdateInterval(updateSchedule)
    documentRef.current.addEventListener('mousemove', timerId)
    return () => {
      documentRef.current.removeEventListener('mousemove', timerId)
    }
  }, [])


  useMemo(() => {
    // console.log('useMemo')
    showBlockOption.current = {
      'show-today': false
    }
    START.current = getDateForamteSlot()
    // console.log(START.current)
  }, [])

  return (
    <div>
      <LoadProvide>
        <GetDatePicker updateSchedule={updateSchedule} date={{ START }} showBlockOption={{ showBlockOption }} />
        <Loader />
      </LoadProvide >

      <div className="col mt-7rem" key={START.current}>
        <div className="container">
          <div className="input-group d-flex flex-row justify-content-between mt-4">
            <div className="form-check form-switch ">
              <input className="form-check-input " type="radio" name="flexRadioDefault" id="work"
                onChange={() => {
                  var myCollapse = document.querySelectorAll('.accordion-collapse')

                  myCollapse.forEach(element => {
                    const checkShow = element.classList.contains('show')
                    if (checkShow) {
                      const bsCollapse = new bootstrap.Collapse(element, {
                        toogle: checkShow
                      })
                    }
                  });
                }}
              /><label
                className="btn padding-btn-0 text-light form-check-label bg-primary rounded-pill w-150px text-center"
                htmlFor="work">Свернуть слоты
              </label>
            </div>
            <div className="form-check form-switch">
              <input className="form-check-input " type="radio" name="flexRadioDefault" id="lunch"
                onChange={() => {
                  var myCollapse = document.querySelectorAll('.accordion-collapse')

                  myCollapse.forEach(element => {
                    const checkShow = element.classList.contains('show')
                    if (!checkShow) {
                      const bsCollapse = new bootstrap.Collapse(element, {
                        toogle: checkShow
                      })
                    }
                  });
                }}
              /><label
                className="btn padding-btn-0 text-light form-check-label bg-primary rounded-pill w-150px text-center"
                htmlFor="lunch">Развернуть слоты
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="container  mw-100 mt-4">
        {(SCHEDULE.length === 0) ? null :
          <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2">
            <div className="col-12 col-sm-12 col-md-12 col-xxl-6">
              <div className="accordion" id="accordionPanelsStayOpenExample">
                {SCHEDULE.schedule.map((roomID, key) => {
                  const time = roomID[0];
                  // console.log(SCHEDULE)
                  if (time >= '08:00' && key <= SCHEDULE.blockCount) {
                    const nextTimeSlot = SCHEDULE.schedule[key + 1][0]
                    const nextDateTimeSlot = `${START.current}, ${nextTimeSlot}`
                    return (
                      <CreateBlock body={roomID} nextDateTimeSlot={nextDateTimeSlot} propskey={key} date={START.current} callback={updateSchedule} showBlockOption={{ showBlockOption }} key={key} />
                    )
                  } else {
                    return null
                  }

                })}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-xxl-6 ">
              <div className="accordion" id="accordionPanelsStayOpenExample">
                {SCHEDULE.schedule.map((roomID, key) => {
                  if (key > 14 && key > SCHEDULE.blockCount) {
                    let nextTimeSlot;
                    let nextDateTimeSlot;
                    if (SCHEDULE.schedule.length - 1 !== key) {
                      nextTimeSlot = SCHEDULE.schedule[key + 1][0]
                      nextDateTimeSlot = `${START.current}, ${nextTimeSlot}`
                    } else {
                      nextTimeSlot = '24:00'
                      nextDateTimeSlot = `${START.current}, ${nextTimeSlot}`
                    }
                    return (
                      <CreateBlock body={roomID} nextDateTimeSlot={nextDateTimeSlot} propskey={key} date={START.current} callback={updateSchedule} showBlockOption={{ showBlockOption }} key={key} />
                    )
                  } else {
                    return null
                  }
                })}
              </div>
            </div>
          </div>
        }
      </div>
    </div>

  );
}