import { useParams } from 'react-router';
import useWebRTC, { LOCAL_VIDEO } from '../useWebRTC2-MB-answer';
import { useNavigate } from 'react-router';
import socket from '../../../socket';
import ACTIONS from '../../../socket/actions';

export default function Room() {
  const history = useNavigate();
  const { id: roomID } = useParams();
  const { clients, provideMediaRef } = useWebRTC(roomID);
  console.log(clients)
  function createShareUrl(roomID) {
    // console.log(roomID)
    return `https://sharescreen.datsy.ru/share/room/${roomID}`
  }

  function disconnect() {
    socket.off(ACTIONS.ADD_PEER);
    socket.off(ACTIONS.SHARE_MESSAGE);
    socket.emit('leave')
  }

  return (
    <div>
      <h2 className="ms-2"> Ссылка для демонстрирующего </h2>
      <button className="ms-2 btn btn-success" value="true" name="oneless" onClick={() => {
        disconnect()
        history(`/sharing/`);
      }}>
        <i className="fa fa-arrow-left"></i>
      </button>
      <div>
        <input className='ms-2' defaultValue={createShareUrl(roomID)} style={{
          width: '120vh',
        }}>
        </input>
      </div>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}>
        <div id="remoteVideosContainer"></div>
        {clients.map((clientID) => {
          return (
            <div key={clientID} id={clientID}>
              <video className="border border-light"
                width='80%'
                height='80%'
                ref={instance => {
                  provideMediaRef(clientID, instance);
                }}
                autoPlay
                muted={clientID}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}