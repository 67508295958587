import { useParams } from 'react-router';
import useWebRTC, { } from '../useWebRTC2-MB';
 
/* function layout(clientsNumber = 2) {
  const pairs = Array.from({ length: clientsNumber })
    .reduce((acc, next, index, arr) => {

      acc.push(arr.slice(index, index + 2));


      return acc;
    }, []);

  const rowsNumber = pairs.length;
  const height = `${100 / rowsNumber}%`;

  return pairs.map((row, index, arr) => {

    if (index === arr.length - 1 && row.length === 1) {
      return [{
        width: '100%',
        height,
      }];
    }

    return row.map(() => ({
      background: '#746a5a',
      width: '50%',
      height,
    }));
  }).flat();
} */

export default function Room() {
  const { id: roomID } = useParams();
  const { clients, provideMediaRef, startCapture } = useWebRTC(roomID);


  return (
    <div className="container-fluid fs-table-10 min-vw-98 table-bordered text-checkbox text-center">
      <div>
        <div className="d-grid gap-2">
          <button className="ms-2 btn btn-success" onClick={startCapture} id="startCapture">
            Начать трансляцию
          </button>
        </div>
      </div>
      {/* <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        height: '100vh',
      }}> */}
      <div id="remoteVideosContainer"></div>
      {clients.map((clientID) => {
        return (
          <div className="d-flex flex-row justify-content-between mt-4" key={clientID} id={clientID}>
            <video
              className='border border-light w-75 p-3 container-fluid fs-table-10'
              ref={instance => {
                provideMediaRef(clientID, instance);
              }}
              autoPlay
              playsInline
            />
          </div>
        );
      })}
    </div>
  );
}