const ACTIONS = {
  JOIN: 'join',
  LEAVE: 'leave',
  SHARE_ROOMS: 'share-rooms',
  SHARE_ROOMS_MOBILE: 'share-room-mobile',
  ADD_PEER: 'add-peer',
  REMOVE_PEER: 'remove-peer',
  RELAY_SDP: 'relay-sdp',
  RELAY_ICE: 'relay-ice',
  ICE_CANDIDATE: 'ice-candidate',
  SESSION_DESCRIPTION: 'session-description',
  MESSAGE: "message",
  SHARE_MESSAGE: "share-message",
  GET_ROOM: 'get-room',
  AUTH: "auth"
};

module.exports = ACTIONS;