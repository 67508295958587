import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { getDateTimeSlot, getDateSlot, getTimeForButton } from '../../../hooks/date-time'
import { getColorMeAsign } from './get-color'
import { showEventButton } from './show-button'
import { useEventButton } from '../function/button'
import { OverlayTrigger, Tooltip, Button, Popover } from 'react-bootstrap';
import { DoubleClick } from '../function/double-clikc'

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';

export default function CreateEvent(props) {
    // console.log('Render Event -----------------------------')
    console.log(props.eventID)
    // console.log(`${props.date} ${props.time}`)
    const [TEXT_SLOT, setTextSlot] = useState([]);
    const [color, setColor] = useState([]);
    const TEXT = useRef()
    const TooltipRef = useRef()
    const BUTTON_FUNCTION = useEventButton(props.callback);

    const eventId = props.eventID
    const eventBody = props.value;

    const timeForButton = getTimeForButton(props.dateTime, 5)

    const showButton = showEventButton(props.time)

    const asign = showButton.asign(
        timeForButton.backDateTimeSlot,
        timeForButton.formatSlot,
        timeForButton.nowDateTime,
        eventBody.assigned_by_id
    )
    const unAsign = showButton.unAsign(
        timeForButton.backDateTimeSlot,
        timeForButton.formatSlot,
        timeForButton.nowDateTime,
        eventBody.assigned_by_name
    )
    const save = showButton.save(
        timeForButton.backDateTimeSlot,
        timeForButton.nowDateTime,
        eventBody.created_by_name
    )
    const deleteSot = showButton.deleteSlot(
        timeForButton.backDateTimeSlot,
        timeForButton.nowDateTime,
        eventBody.created_by_name
    )

    const colorEvent = getColorMeAsign(eventBody.created_by_name, props.time)
    // return (
    // eventArray.map((eventValue, key) => {
    // console.log(`${eventId}${eventBody.text}`)
    // console.log(eventId)

    const { text } = eventBody

    useEffect(() => {
        // console.log('Пришел новый текст')
        setColor(colorEvent)
    }, [text]);

    useEffect(() => {
        // console.log('Нажали кнопку и изменили текст')
        const newColor = TEXT_SLOT !== eventBody.text ? 'bg-warning' : colorEvent
        // console.log(newColor)
        setColor(newColor)
    }, [TEXT_SLOT]);

    useMemo(() => {
        // console.log('Изменился')
        setColor(colorEvent)
        setTextSlot(eventBody.text)
    }, []);

    useMemo(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            var tooltip = bootstrap.Tooltip.getInstance(tooltipTriggerEl)
            if (tooltip !== null) {
                return tooltip.dispose()
            }
        })
    }, [text]);

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            new bootstrap.Tooltip(tooltipTriggerEl)
        })
    }, [text]);


    const assignedTime = eventBody.assigned_at === null ? 'Ивент не взят' : eventBody.assigned_at
    const viwBlockButoon = !!(asign, unAsign, save, deleteSot)
    return (
        <div className="btn-group timeslot" role="group" aria-label="Basic mixed styles example" event-id="" key={`${eventId}`}>
            <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip>
                        {assignedTime}
                    </Tooltip>
                }
            >
                <div className="w-25 p-2 form-control text-nowrap timeslot-assign" type="text">{eventBody.assigned_by_name}</div>
            </OverlayTrigger>

            <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip>
                        {`${eventBody.created_by_name} ${eventBody.created}`}
                    </Tooltip>
                }
            >
                <div className="input-group">

                    {
                        eventBody.text.toLowerCase().indexOf("кну") >= 0 &&
                        <div className="btn btn-danger w-50px" type="text">
                            <i className="fa-solid fa-headset"></i>
                        </div>
                    }
                    <input
                        className={`${color} p-2 form-control  border-secondary comment-slot`} 
                        key={eventBody.text}
                        type="text"
                        defaultValue={eventBody.text}
                        onChange={(e) => {
                            setTextSlot(e.target.value)
                            console.log(TEXT.current)
                        }}
                        onDoubleClick={() => {
                            console.log('onDoubleClick')
                            DoubleClick(eventBody.text)
                        }}
                    >
                    </input>
                </div>
            </OverlayTrigger>

            <div className={`btn-group ${viwBlockButoon === true ? 'w-40' : ''}`}>

                {
                    !!asign &&
                    <button className="btn btn-success " type="button" defaultValue=""
                        onClick={() => {
                            BUTTON_FUNCTION.asign(eventBody.id)
                        }}
                    >
                        <i className="fa fa-arrow-down"></i>
                    </button>
                }
                {
                    !!unAsign &&
                    <button className="btn btn-warning " type="button" defaultValue=""
                        onClick={() => {
                            BUTTON_FUNCTION.unAsign(eventBody.id)
                        }}
                    >
                        <i className="fa fa-arrow-up"></i>
                    </button>
                }

                {
                    !!save &&
                    <button className="btn btn-info " type="button" defaultValue=""
                        onClick={() => {
                            BUTTON_FUNCTION.save(TEXT_SLOT, eventBody.id)

                        }}
                    >
                        <i className="fa fa-save"></i>
                    </button>
                }

                {
                    !!deleteSot &&
                    <>
                        <button className="btn btn-danger " type="button" defaultValue=""
                            data-bs-toggle="modal" data-bs-target={`#exampleModal${eventBody.id}`}
                        >
                            <i className="fa fa-times"></i>
                        </button>

                        <div className="modal fade" id={`exampleModal${eventBody.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered ">
                                <div className="modal-content bg-dark">

                                    <div className="modal-body">
                                        Ивент
                                        <br></br>
                                        <span className="text-primary">`{eventBody.text}`</span>
                                        <br></br>будет удален, подтвердите действие
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                            onClick={async () => {
                                                BUTTON_FUNCTION.deleteSlot(eventBody.id)
                                                props.addSlot()
                                            }}
                                        > Удалить</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>

    )
    //     })

    // )
}