import { setDateTimeSlot, setDateSlot, getDateTimeSlot, getDateSlot, getDateForamteSlot, creatNowDate, getDateWeekForButton } from '../../../hooks/date-time'
function createDatepickerButton(callBackDateTime) {
    const weekDate = getDateWeekForButton()
    return { 
        goOverDate: async () => {
            callBackDateTime()
        },
        previousWeek: ({START, END, DATE_PICK}) =>  {
            const currentDate = weekDate.getLessDate(START.current, END.current, DATE_PICK.current)
            const { pickDate } = currentDate
            DATE_PICK.current = pickDate
            callBackDateTime()
    
        },
        todayWeek: async ({START, END, DATE_PICK}) => {
            const currentDate = weekDate.weekButton()
            const { pickDate } = currentDate
            DATE_PICK.current = pickDate
            callBackDateTime()
        },
        nextWeek: ({START, END, DATE_PICK}) => {
            const currentDate = weekDate.getNextDate(START.current, END.current, DATE_PICK.current)
            const { pickDate } = currentDate
            DATE_PICK.current = pickDate
            callBackDateTime()
        }
    }
}
export { createDatepickerButton }