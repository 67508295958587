import { getDateSlot, getDateForamteSlot } from '../../../hooks/date-time'
import { api, shedulePath, sheduleReservPath } from '../../../service/api-url';

function createSetter(props) {

    return {
        saveScheduleAbsents: async ({schedule}, group, {DATE_PICK: {current}}) => {
            let array = {}
            array['state'] = schedule
            array['date'] = current
            array['group'] = group
            console.log(array)
            // const url = window.location.origin + '/api/schedule/save-shedule/all-temprary.php';
            const url = `${api()}${shedulePath()}/save-shedule/all-temprary.php`;
            let saveScheduleRes = fetch(url, {
                method: 'POST',
                // headers: {
                //     "content-type": "application/json; charset=UTF-8"
                // },
                body: JSON.stringify(array)
            })
        },

        saveReservationRegular: async ({reservation}, group, {DATE_PICK: {current}}) => {
            let array = {}
            array['reserv-list'] = reservation
            array['date'] = current
            array['group'] = group

            // const url = window.location.origin + '/api/reservation/save-reservation/reserv-regular.php';
            const url = `${api()}${sheduleReservPath()}/save-reservation/reserv-regular.php`;
            let saveScheduleRes = fetch(url, {
                method: 'POST',
                // headers: {
                //     "content-type": "application/json; charset=UTF-8"
                // },
                body: JSON.stringify(array)
            })
        },
        saveReservationAbsents: async ({reservation}, group, {DATE_PICK: {current}}) => {
            let array = {}
            array['reserv-list'] = reservation
            array['date'] = current
            array['group'] = group

            // const url = window.location.origin + '/api/reservation/save-reservation/reserv-temprary.php';
            const url = `${api()}${sheduleReservPath()}/save-reservation/reserv-temprary.php`;
            let saveScheduleRes = fetch(url, {
                method: 'POST',
                // headers: {
                //     "content-type": "application/json; charset=UTF-8"
                // },
                body: JSON.stringify(array)
            })
        }
    }
}

export { createSetter }