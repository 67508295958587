import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router';
// import 'bootstrap';
import { useState, useEffect, useRef, useMemo, useContext, createContext } from 'react';
import RequireAuth from './service/RequireAuth'

import Auth from './pages/auth'
import Main from './pages/main';
// import MainTest from './pages/main-test';
import Logs from './pages/logs';
import Users from './pages/users';
import Edit from './pages/edit';
import Timetable from './pages/timetable';
import Reports from './pages/reports';
import SharingTest from './pages/sharing-test/App';
import AddUser from './pages/add-user';
import NotFound from './pages/not-found';
// import Sharing from './pages/sharing/index';

// import TimetableTest from './pages/timetable-test';
import Test from './pages/test-callback/index-uislider';
import { CheckAuth } from './service/auth-check'
import Navbar from './components/Navbar';
// const client = window.location.host === "app.datsy-test.ru" ? true : false;
import { AuthButton, LoginPage } from './auth-button'
import useProvideAuth from './service/privide-auth'
import { LoadProvide } from './context/LoadProvider'
const authContext = createContext();

function useAuth() {
  return useContext(authContext);
}

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  useMemo(() => {
  }, []);
  return (
    <authContext.Provider value={auth}>
      {auth.user === false
        ? children
        : auth.user === true
          ? children
          : null}

    </authContext.Provider>
  );
}

export default function App() {
  // console.log('Render App')
  return (

    <ProvideAuth>
      <Navbar authContext={authContext} />
      <Routes>
        <Route path="/auth" element={<Auth authContext={authContext} />} />
        <Route element={<RequireAuth authContext={authContext} />}>
          <Route path="/" element={<Main />} />
          <Route path="/logs" element={<Logs />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/add/" element={<AddUser />} />
          <Route path="/users/edit/user=:id" element={<Edit />} />
          <Route path="/timetable" element={<Timetable />} />
          <Route path="/reports" element={<Reports />} />
          <Route path='/sharing/*' element={<SharingTest />}></Route>
        </Route>
        {/* Отдельный роут для тех кто шерит экран без проверки авторизации */}
        <Route path='/share/*' element={<SharingTest />}/>
        <Route path='*' element={<NotFound/>}/>
      </Routes>
    </ProvideAuth>

  );
}
