import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useState, useEffect, useRef, createContext, useContext } from 'react';


const RequireAuth = ({ authContext, children }) => {
    const auth = useContext(authContext)
    // console.log(auth.user)
    const location = useLocation();

    return (
        auth.user === false 
            ? <Navigate to="/auth"  state={{ from: location }} replace />
            : <Outlet />
    );
}

export default RequireAuth;