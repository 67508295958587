import { CONFIG } from '../config';
import { createSetter } from '../functions/setter-schedule'

function CreateButtonSetter({schedule, reservation, datePick}) {
    // const { schedule } = props.propSchedule
    // const { reservation } = props.propReserved

    const buttonFunc = createSetter()

    return (
        <div className="d-flex flex-row justify-content-between mt-4">
            <div className="">
                <button type="button" className="btn btn-success text-light " id="save_temp_shedule"
                    onClick={() => {
                        buttonFunc.saveScheduleAbsents(schedule, '1', datePick)
                    }}
                ><i className="fa fa-save"></i> Сохранить временное расписание</button>
            </div>
            <div>
                <button type="button" className="btn btn-primary mx-4" id="save_reg_reserv"
                    onClick={() => {
                        buttonFunc.saveReservationRegular(reservation, '1', datePick)
                    }}
                ><i className="fa fa-save"></i> Сохранить бронь навсегда </button>
                <button type="button" className="btn btn-success" id="save_temp_reserv"
                    onClick={() => {
                        buttonFunc.saveReservationAbsents(reservation, '1', datePick)
                    }}
                ><i className="fa fa-save"></i> Сохранить бронь разово </button>
            </div>
        </div>
    )
}

export { CreateButtonSetter }