const DATE_TIME_SLOT = {
    dateTimeslot: '',
    dateSlot: '',
    dateSlotForamte: '',
    formateDateTimeSlot: ''
}

const OPTION = {
    timeZone: 'Europe/Moscow',
};

const getBacktDate = () => {
    const nowDate = DATE_TIME_SLOT.dateSlotForamte === '' ? creatNowDateFormate() : DATE_TIME_SLOT.dateSlotForamte
    let nowDataTimeFuture = new Date(nowDate);
    nowDataTimeFuture.setDate(nowDataTimeFuture.getDate() - 1);
    const backDate = nowDataTimeFuture.toLocaleDateString('ru-Ru', OPTION).split('.').join('-')

    return backDate
}

const setDateTimeSlot = (dateTime) => {
    // DATE_TIME_SLOT.dateTimeslot = '2022-02-05, 17:25:01'
    DATE_TIME_SLOT.dateTimeslot = dateTime
}

const getDateTimeSlot = () => {
    return DATE_TIME_SLOT.dateTimeslot
}

const setDateSlot = (dateTime) => {
    // console.log('setDateSlot')
    // console.log(DATE_TIME_SLOT.dateSlot)
    DATE_TIME_SLOT.dateSlot = dateTime
}

const getDateSlot = () => {
    const nowDate = DATE_TIME_SLOT.dateSlot === '' ? creatNowDate() : DATE_TIME_SLOT.dateSlot
    // console.log('getDateSlot')
    // console.log(DATE_TIME_SLOT.dateSlot)
    return nowDate
}

const creatNowDate = () => {
    // console.log('creatNowDate')
    let nowDataTimeBack = new Date();
    let dateFormat = nowDataTimeBack.toLocaleDateString('ru-Ru', OPTION).split('.').join('-')
    DATE_TIME_SLOT.dateSlot = dateFormat
    return dateFormat;
}


const setDateForamteSlot = (dateTime) => {
    // console.log('setDateSlot')
    // console.log(DATE_TIME_SLOT.dateSlot)
    DATE_TIME_SLOT.dateSlotForamte = dateTime
}

const getDateForamteSlot = (date) => {
    if (!date) {
        const nowDate = DATE_TIME_SLOT.dateSlotForamte === '' ? creatNowDateFormate() : DATE_TIME_SLOT.dateSlotForamte
        return nowDate
    } else {
        const newDate = date.split('-').reverse().join('-')
        return newDate
    }
}

const getDateForamte = (date) => {
    if (!date) {
        return null
    } else {
        const newDate = date.split('-').reverse().join('-')
        return newDate
    }
}

const creatNowDateFormate = () => {
    // console.log('creatNowDate')
    let nowDataTimeBack = new Date();
    let dateFormat = nowDataTimeBack.toLocaleDateString('ru-Ru', OPTION).split('.').reverse().join('-')
    DATE_TIME_SLOT.dateSlotForamte = dateFormat
    return dateFormat;
}

const checkNowDateSlot = (timeSlot, nextTimeSlot) => {
    function checkTimeColor(dateTime) {
        let nowDataTimeBack = new Date(dateTime);
        nowDataTimeBack.setMinutes(nowDataTimeBack.getMinutes() - 5);
        let backFormat = nowDataTimeBack.toLocaleString('ru-Ru').split(',')
        backFormat = backFormat[0].split('.').reverse().join('-') + ',' + backFormat[1]
        return backFormat
    }

    const backFormat = checkTimeColor(timeSlot)
    const futureFormat = checkTimeColor(nextTimeSlot)

    return { backFormat, futureFormat }
}

const getTimeForButton = (dateTime, read) => {
    const nowDateTime = DATE_TIME_SLOT.dateTimeslot

    const formatSlot = dateTime

    let nowDataTimeBack = new Date(formatSlot);
    nowDataTimeBack.setMinutes(nowDataTimeBack.getMinutes() - read);
    let backDateTimeSlot = nowDataTimeBack.toLocaleString('ru-Ru').split(',')
    backDateTimeSlot = backDateTimeSlot[0].split('.').reverse().join('-') + ',' + backDateTimeSlot[1]


    let nowDataTimeFuture = new Date(formatSlot);
    nowDataTimeFuture.setMinutes(nowDataTimeFuture.getMinutes() + 5);
    let futureFormat = nowDataTimeFuture.toLocaleString('ru-Ru').split(',')
    futureFormat = futureFormat[0].split('.').reverse().join('-') + ',' + futureFormat[1]

    return { nowDateTime, formatSlot, backDateTimeSlot, futureFormat }
}

const createCurrentDate = (start, end) => {
    const dtStart = new Date(start); // current date of week
    const dtEnd = new Date(end); // current date of week
    return { dtStart, dtEnd }
}

const getDateWeekForButton = () => {
    return {

        getLessDate: (start, end, pickDateCurrent) => {
            const dt = new Date(pickDateCurrent); // current date of week
            const currentWeekDay = dt.getDate();
            const currentStartDate = new Date(new Date(dt).setDate(currentWeekDay - 7));
            const pickDate = createDate(currentStartDate)

            const date = createCurrentDate(start, end)
            const days = (date.dtEnd - date.dtStart) / (1000 * 60 * 60 * 24);
            const currentDate = createCurrentDate(start, end)

            currentDate.dtStart.setDate(currentDate.dtStart.getDate() - days - 1);
            const wkStart = createDate(currentDate.dtStart)

            currentDate.dtEnd.setDate(currentDate.dtEnd.getDate() - days - 1);
            const wkEnd = createDate(currentDate.dtEnd)

            return { wkStart, wkEnd, pickDate }
        },
        getNextDate: (start, end, pickDateCurrent) => {
            const dt = new Date(pickDateCurrent); // current date of week
            const currentWeekDay = dt.getDate();
            const currentStartDate = new Date(new Date(dt).setDate(currentWeekDay + 7));
            const pickDate = createDate(currentStartDate)

            const date = createCurrentDate(start, end)
            const days = (date.dtEnd - date.dtStart) / (1000 * 60 * 60 * 24);
            const currentDate = createCurrentDate(start, end)

            currentDate.dtStart.setDate(currentDate.dtStart.getDate() + days + 1);
            const wkStart = createDate(currentDate.dtStart)
            console.log(currentDate.dtStart)

            currentDate.dtEnd.setDate(currentDate.dtEnd.getDate() + days + 1);
            const wkEnd = createDate(currentDate.dtEnd)
            console.log(wkEnd)

            return { wkStart, wkEnd, pickDate }
        },
        weekButton: (date) => {
            let pickDate
            if (!date) {
                pickDate = creatNowDateFormate()
            } else {
                pickDate = date
            }
            const dt = new Date(pickDate); // current date of week
            const currentWeekDay = dt.getDay();
            const lessDays = currentWeekDay === 0 ? 6 : currentWeekDay - 1;
            const currentStartDate = new Date(new Date(dt).setDate(dt.getDate() - lessDays));
            const wkStart = createDate(currentStartDate)
            const currentEndDate = new Date(new Date(currentStartDate).setDate(currentStartDate.getDate() + 6));
            const wkEnd = createDate(currentEndDate)
            return { wkStart, wkEnd, pickDate }
        }
    }
}

const getDateForButton = () => {
    return {

        getLessDate: (pickDateCurrent) => {
            const dt = new Date(pickDateCurrent); // current date of week
            const currentWeekDay = dt.getDate();
            const currentStartDate = new Date(new Date(dt).setDate(currentWeekDay - 1));
            const pickDate = createDate(currentStartDate)
            return { pickDate }
        },
        getNextDate: (pickDateCurrent) => {
            const dt = new Date(pickDateCurrent); // current date of week
            const currentWeekDay = dt.getDate();
            const currentStartDate = new Date(new Date(dt).setDate(currentWeekDay + 1));
            const pickDate = createDate(currentStartDate)

            return { pickDate }
        },
        nowDate: (date) => {
            let pickDate
            if (!date) {
                pickDate = creatNowDateFormate()
            } else {
                pickDate = date
            }
            return { pickDate }
        }
    }
}

const createDate = (date) => {
    const currentDate = new Date(date);
    const dt = currentDate.toLocaleDateString('ru-Ru')
    const formatDt = dt.split('.').reverse().join('-')
    return formatDt;
}
export {
    setDateTimeSlot,
    getDateTimeSlot,

    getBacktDate,

    getDateSlot,
    setDateSlot,

    getDateForamte,
    getDateForamteSlot,
    setDateForamteSlot,

    createDate,
    creatNowDate,
    creatNowDateFormate,

    checkNowDateSlot,
    getTimeForButton,
    getDateForButton,
    getDateWeekForButton
}