import { CONFIG } from '../config';

const setColor = (element, data) => {
    const canvas = element.querySelectorAll("#slider-round")

    // canvas.forEach((weekElem, weekKey) => {
    var connect = element.querySelectorAll('.noUi-connect');
    connect.forEach((statusElem, statusKey) => {
        let statusValue = '0'
        if (!!data[statusKey]) {
            statusValue = data[statusKey].status
        }
        // console.log(CONFIG.class[statusValue])
        statusElem.classList.add(CONFIG.class[statusValue]);
    });
    // });
}

export { setColor }