import { getDateSlot, getDateForamteSlot } from '../../../hooks/date-time'
import { api, shedulePath, sheduleReservPath } from '../../../service/api-url';

const getSchedule = async (date) => {
    const arrayResult = {
        schedule: {},
        // reservation: {}
    }
    // `let date = getDateForamteSlot()`
    // try {
    // console.log('getSchedule')
    let getScheduleResult = await fetch(`${api()}${shedulePath()}/get-all-shedule.php?date=${date}`, {
        "body": null,
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    })

    const scheduleResult = await getScheduleResult.json();

    Object.keys(scheduleResult).map((userBody, key) => {
        if (scheduleResult[userBody].error) {
            arrayResult.schedule[userBody] = scheduleResult[userBody]
        } else {
            arrayResult.schedule[userBody] = scheduleResult[userBody][date]
        }
    })

    // console.log('getReservation')
    return arrayResult
}

const getReserv = async (date) => {
    const arrayResult = {
        reservation: {}
    }
    // let date = getDateForamteSlot()

    let getReservationResult = await fetch(`${api()}${sheduleReservPath()}/temporary.php?date=${date}`, {
        "body": null,
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    })

    const reservationResult = await getReservationResult.json();
    arrayResult.reservation = reservationResult[date]["count-reservation"]
    // console.log(arrayResult)

    return arrayResult
}

export { getSchedule, getReserv }