import { useState, useEffect, useRef, useCallback } from 'react';
import { CONFIG } from '../config';
import { radioStatus } from '../functions/get-radio-status'

function CreateSchedule(props) {
    // console.log("CreateSchedule")
    const [SCHEDULE, setSchedule] = useState([])

    const { schedule } = props.propSchedule
    const callback = props.propCallback
    // if(SCHEDULE){
    //     setSchedule(schedule)
    // }

    Object.entries(schedule).forEach(([userId, dateBody], i) => {
        // console.log(timeStatus)

        // console.log(dateBody)
    })
    return (
        // (SCHEDULE.length === 0) ? <></> :
        Object.entries(schedule).map(([userId, dateBody], i) => {


            return (
                // !dateBody.timeStatus
                //     ? <tr>
                //         {
                //             schedule[userId]['user-name']
                //         }
                //        <td colspan="max">ячейка заголовка</td>
                //     </tr>
                //     : 
                    <tr
                        key={userId}
                    >
                        <td>{
                            schedule[userId]['user-name']
                        }</td>
                        {
                            !dateBody.timeStatus
                                ? null
                                : Object.entries(dateBody.timeStatus).map(([time, stat]) => {
                                    let keyCheck = userId + time
                                    let status
                                    // if (userId === '25') {
                                    if (time === '00:30') {
                                        console.log(userId)
                                    }
                                    // }
                                    if (schedule[userId]['timeStatus']) {
                                        status = schedule[userId]['timeStatus'][time]
                                        return (
                                            time < '08:00' ? null :
                                                time > '22:30' ? null :
                                                    <td
                                                        className='collum-hov'
                                                        key={keyCheck}
                                                        onClick={(e) => {
                                                            const scheduleUpdate = {}
                                                            scheduleUpdate['schedule'] = props.propSchedule.schedule
                                                            scheduleUpdate['reservation'] = props.propSchedule.reservation
                                                            console.log(props.propSchedule)
                                                            const RADIO_STATUS = radioStatus().getRadioStatus()
                                                            // console.log(schedule)
                                                            scheduleUpdate.schedule[userId]['timeStatus'][time] = scheduleUpdate.schedule[userId]['timeStatus'][time] === RADIO_STATUS ? '0' : RADIO_STATUS
                                                            // setSchedule([])
                                                            callback(scheduleUpdate)
                                                        }}
                                                    ><input
                                                        className={CONFIG.defaultClass + CONFIG.class[status]}
                                                        type="checkbox"
                                                        status={status}
                                                        data-timeslot={time}
                                                        user={userId}
                                                        readOnly
                                                        checked={CONFIG.cheked[status]}
                                                        id={keyCheck}
                                                    ></input>
                                                    </td>
                                        );
                                    }
                                    else {
                                        return null
                                    }
                                })
                        }
                    </tr>
            )
        })

    )
}

export { CreateSchedule }