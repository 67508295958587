import { useEffect, useRef, useCallback } from 'react';
import freeice from 'freeice';
import useStateWithCallback from '../../hooks/useStateWithCallback';
import socket from '../../socket';
import ACTIONS from '../../socket/actions';
import STUN_TURN from '../../hooks/STUN_TURN';

export const LOCAL_VIDEO = 'LOCAL_VIDEO';


export default function useWebRTC(roomID) {
  function logConsole(text) {
    // console.log(text)
  }
  /* Логировать соккеты */
  useEffect(() => {
    socket.on("*", (event, data) => {
      logConsole(event)
      logConsole(data)
      if (data.iceCandidate !== undefined) {
        logConsole(data.iceCandidate.candidate)
      }
      if (data.sessionDescription !== undefined) {
        logConsole(data.sessionDescription.type)
      }
    });
  }, []);
  /* ------------------------------------- */

  /* Константы */
  const [clients, updateClients] = useStateWithCallback([]);
  const pc = useRef({});
  const localMediaStream = useRef(null);
  const remoteVideoContainer = document.querySelector('#remoteVideosContainer');
  const peerMediaElements = useRef({
    [LOCAL_VIDEO]: null,
  });
  useEffect(() => {
    var peerDatabase = {};

    var config = {
      peerConnectionConfig: {
        iceServers: [
          { "url": "stun:23.21.150.121" },
          { "url": "stun:stun.l.google.com:19302" }
        ]
      },
      peerConnectionConstraints: {
        optional: [
          { "DtlsSrtpKeyAgreement": true }
        ]
      }
    }


    /* Добавить per собеседника */
    function addPeer(remoteId) {
      var peer = new Peer(config.peerConnectionConfig, config.peerConnectionConstraints);
      peer.pc.onicecandidate = function (event) {
        if (event.candidate) {
          send('candidate', remoteId, {
            label: event.candidate.sdpMLineIndex,
            id: event.candidate.sdpMid,
            candidate: event.candidate.candidate,
          });
        }
      };
      peer.pc.onaddstream = function (event) {

        let element = document.querySelector('#remoteVideosContainer');
        element.appendChild(peer.remoteVideoEl);
        peer.remoteVideoEl.srcObject = event.stream
      };

      peerDatabase[remoteId] = peer;

      return peer;
    }

    function answer(remoteId) {
      var pc = peerDatabase[remoteId].pc;
      pc.createAnswer(
        function (sessionDescription) {
          pc.setLocalDescription(sessionDescription);
          send('answer', remoteId, sessionDescription);
        },
        error
      );
    }

    function handleMessage(message) {
      let type = message.type;
      let from = message.from;
      let pc = (peerDatabase[from] || addPeer(from)).pc;

      // console.log('received ' + type + ' from ' + from);

      switch (type) {
        case 'offer':
          pc.setRemoteDescription(new RTCSessionDescription(message.payload), function () { }, error);
          answer(from);
          break;
        case 'answer':
          pc.setRemoteDescription(new RTCSessionDescription(message.payload), function () { }, error);
          break;
        case 'candidate':
          if (pc.remoteDescription) {
            pc.addIceCandidate(new RTCIceCandidate({
              sdpMLineIndex: message.payload.label,
              sdpMid: message.payload.id,
              candidate: message.payload.candidate
            }), function () {
              
            }, error);
          }
          break;
      }

    }
    socket.on(ACTIONS.MESSAGE, handleMessage);

    
    var Peer = function (pcConfig, pcConstraints) {
      this.pc = new RTCPeerConnection(pcConfig, pcConstraints);
      this.remoteVideoEl = document.createElement('video');
      this.remoteVideoEl.controls = true;
      this.remoteVideoEl.muted = "muted";
      this.remoteVideoEl.autoplay = true;
      this.remoteVideoEl.playsInline = true;
    }
    
    function send(type, to, payload) {
      //   console.log('sending ' + type + ' to ' + to);
      
      socket.emit('message', {
        to: to,
        type: type,
        payload: payload,
        why: 'PC'
      });
    }
    
    function toggleLocalStream(pc) {
      if (localMediaStream) {
        (!!pc.getLocalStreams().length) ? pc.removeStream(localMediaStream) : pc.addStream(localMediaStream);
      }
    }
    
    function error(err) {
      console.log(err);
    }
    
    
    
    
    
  }, []);
  
  useEffect(() => {
    socket.emit('message', { to: roomID, payload: null, type: "init" })
  }, [roomID]);
  // const provideMediaRef = useCallback((id, node) => {
    //   peerDatabase[id].remoteVideoEl = node;
    // }, []);
    
    
  return {
    clients,
    // provideMediaRef
  };
}