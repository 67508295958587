
export default async function geticeServers(){
    // Simple POST request with a JSON body using fetch
    // console.log('componentDidMount')
    // document.cookie = "PHPSESSID=a8mvlc2rqv14numb2qf1mlomdrpq5opj";
    
    // let scheduleRes = await fetch(`//localhost:3002/getMenus.php`)
    // let getResult = await fetch(`${window.location.origin}/calendar-red/getMenus.php`, {
    let getResult = await fetch(`https://datsy.ru/api/get-stun-turn/`, {
        "method": "GET",
        
        
    })
    const result = await getResult.json();
    return result;
}
