import { useState, useEffect, useRef } from 'react';
import { getDateSlot, getDateForamteSlot, createDate } from '../../../hooks/date-time'
import { CONFIG } from '../config';

const getDateMap = (start, end) => {
    const startDate = start === undefined ? getDateForamteSlot() : start
    const arrayDateMap = {}

    const nowDate = new Date(startDate);
    // const nowDate = new Date('2022-04-17');
    let weekNumber = nowDate.getDay()
    let weekParse = weekNumber === 0 ? 6 : CONFIG.week[weekNumber]['number'] - 1

    const wkStart = new Date(new Date(nowDate).setDate(nowDate.getDate() - weekParse));
    
    let wkEnd
    if (start === undefined && end === undefined) {
        wkEnd = new Date(new Date(wkStart).setDate(wkStart.getDate() + 6));
    }else{
        wkEnd = new Date(end);
    }


    while (wkStart <= wkEnd) {
        const weekNumber = wkStart.getDay()
        // console.log(weekNumber)
        // const weekParse = CONFIG.week[weekNumber]['number']
        const currentDate = createDate(wkStart)
        // console.log(currentDate)
        // arrayDateMap.push({"day": currentDate, "valueWeek": CONFIG.week[weekNumber].valueTable})
        arrayDateMap[currentDate] = {"valueTable" : `${CONFIG.week[weekNumber].valueTable} ${currentDate}`}
        const test = wkStart.getDate()
        wkStart.setDate(wkStart.getDate() + 1)
    }

    // console.log(arrayDateMap)


    return arrayDateMap

}

export { getDateMap }