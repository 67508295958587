import { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router';
import useLoad from '../../hooks/useLoad'
const Loader = ({ loadContext }) => {
    const  loadding  = useLoad();
    // console.log(loadding)

    useEffect(() => {
       
    }, []);

    return (
        loadding.load === false ? null : 
        <div className=" w-25 container position-fixed top-50 start-50 translate-middle-x mw-100" style={{ "zIndex": "3", "top": "146px" }} id="alert-slot-load">
            <div className="rounded-pill alert alert-warning">
                <div className="col-12 position-relative top-0 start-50 translate-middle-x" style={{ "zIndex": "33" }}>
                    <div className="d-flex justify-content-center">
                        <strong className="" style={{ "zIndex": "33" }}>Загрузка слотов...</strong>
                    </div>
                </div>

                <div className="col-12  text-danger" style={{ "zIndex": "33" }}>
                    <div className="d-flex justify-content-center">
                        <div className="d-flex justify-content-center spinner-border" role="status" style={{ "zIndex": "33" }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Loader };
