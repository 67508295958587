import { CONFIG } from '../config';

function CreateTime(props) {
    console.log("CreateTime")
    const { propTime } = props
    return (
        propTime === undefined ? null :
            Object.entries(propTime).map(([time]) => {
                return (
                    time < '08:00' ? null :
                        time > '22:30' ? null :
                            <td
                                key={time}
                                className='collum-hov'
                            >{time}</td>
                );
            })
    )
}
export { CreateTime }